<template>
    <div>
        <van-tabbar v-model="newChecked">
            <van-tabbar-item name="home" to="/test">
                <span>工作台</span>
                <img slot="icon"
                     slot-scope="homeProps"
                     :src="homeProps.active ? icon.homeActive : icon.home">
            </van-tabbar-item>
            <van-tabbar-item name="house" to="/seekHouseCollection">
                <span>寻找房源</span>
                <img slot="icon"
                     slot-scope="houseProps"
                     :src="houseProps.active ? icon.houseActive : icon.house">
            </van-tabbar-item>
            <van-tabbar-item name="entrust" to="/seekHouseCollection">
                <span>业主委托</span>
                <img slot="icon"
                     slot-scope="entrustProps"
                     :src="entrustProps.active ? icon.entrustActive : icon.entrust">
            </van-tabbar-item>
            <van-tabbar-item name="my" to="/TemporaryButton">
                <span>我的</span>
                <img slot="icon"
                     slot-scope="myProps"
                     :src="myProps.active ? icon.myActive : icon.my">
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import {Tabbar, TabbarItem} from 'vant';
    export default {
        components: {
            [Tabbar.name]:Tabbar,
            [TabbarItem.name]:TabbarItem,
        },
        name: "rx-tabbar",
        props: {
            checked: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                newChecked:this.checked,
                icon: {
                    home: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/home/home.png',
                    homeActive: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/home/home-active.png',
                    house: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/house/house.png',
                    houseActive: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/house/house-active.png',
                    entrust: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/entrust/entrust.png',
                    entrustActive: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/entrust/entrust-active.png',
                    my: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/my/my.png',
                    myActive: 'http://himihome.oss-cn-qingdao.aliyuncs.com/tabbar/my/my-active.png',
                }
            }
        },
        methods:{
            closeMobileModel(){


            }
        }
    }
</script>

<style lang="less" scoped>


</style>